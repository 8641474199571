import Image from "react-bootstrap/Image";

export default function IntroductionPage() {
  return (
    <div id="introduction-page">
      <p className="h1 my-5 text-center">Kisszékelyi Dóra dietetikus honlapja</p>
      <Image src="/images/introduction.webp" alt="Kisszékelyi Dóra Anita" width={"30%"} style={{ maxWidth: "280px" }} className="me-3 mb-2 float-start" rounded></Image>
      <p className="h3">Üdvözlöm!</p>
      <p>
        Sokan foglalkoznak táplálkozási tanácsadással: elég bekapcsolni a tévét, vagy az autóban ülve hallgatni a rádiót, máris rengeteg étkezéssel kapcsolatos információ zúdul
        ránk. Ha Ön is úgy érzi, hogy elveszett az egymásnak ellentmondó hírek sokaságában, és szeretne megbízható iránymutatást kapni, akkor jó helyen jár, hiszen a dietetikus az
        egyetlen felsőfokú végzettségű szakember, aki jogilag táplálkozási diagnózist állíthat fel és étrendi terápiát folytathat.
      </p>
      <p className="h3">Bemutatkozás</p>
      <p>
        Kisszékelyi Dóra vagyok, dietetikus, három csodás gyermek édesanyja. Tanulmányaimat a Semmelweis Egyetem Egészségtudományi Karán végeztem, negyedéves hallgatóként
        Köztársasági Ösztöndíjban részesültem, majd kitűnő minősítéssel diplomáztam.
      </p>
      <p>
        Egyetemi éveim alatt részt vettem a GYERE programban, amelynek célja a gyermekek egészségtudatos táplálkozásra való nevelése volt. Az ott tanultakat gyermekeim születése
        óta az ő nevelésük során is sikeresen alkalmazom.
      </p>
      <p>
        Diplomám megszerzése után egy budapesti kórházban dolgoztam klinikai dietetikusként, ahol több osztály dietetikai ellátásáért voltam felelős. Jelenleg dietetikai
        tanácsadással foglalkozom magánrendelőmben, Tökölön.
      </p>
      <p>A bizonyítékokon alapuló orvoslásban hiszek, követem a szakmai irányelveket és protokollokat, hiszen csak így tudom a legjobb ellátást nyújtani a hozzám fordulóknak.</p>
      <p>
        Úgy vélem, hogy az élethosszig tartó tanulás elengedhetetlen ebben a szakmában, ezért rendszeresen részt veszek tanfolyamokon és továbbképzéseken, valamint figyelemmel
        kísérem a legfrissebb kutatási eredményeket is.
      </p>
      <p>Várom Önt szeretettel!</p>
      <p className="h3">Rendelő</p>
      <p>
        A rendelő gyermekbarát, ami nem csupán azt jelenti, hogy sok szeretettel várom a kis pácienseket, hanem azt is, hogy nyugodtan elhozhatja gyermekeit a tanácsadásra. Amíg mi
        mindent átbeszélünk Önnel kapcsolatban, addig ők nyugodtan játszhatnak a nekik kialakított játszó sarokban.
      </p>
      <Image src="/images/rendelo.webp" alt="Dóra Dietetika rendelő" width={"100%"} className="me-3 mb-2 float-center" rounded></Image>
    </div>
  );
}
