import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Banner from "./components/Banner/Banner";
import Content from "./components/Content/Content";
import Footer from "./components/Footer/Footer";
import IntroductionPage from "./pages/IntroductionPage";
import ServicesPage from "./pages/ServicesPage";
import InformationPage from "./pages/InformationPage";
import PricesPage from "./pages/PricesPage";
import DocumentsPage from "./pages/DocumentsPage";
import ContactsPage from "./pages/ContactsPage";
import NotFoundPage from "./pages/NotFoundPage";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Banner></Banner>
      <Content>
        <Routes>
          <Route path="/" element={<IntroductionPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/information" element={<InformationPage />} />
          <Route path="/prices" element={<PricesPage />} />
          <Route path="/documents" element={<DocumentsPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Content>
      <Footer></Footer>
    </div>
  );
}

export default App;
