import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";

function Header() {
  const location = useLocation();
  return (
    <header>
      <Navbar expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand href="/">
            <Image src="/logo.png" alt="Logo" width={160} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav activeKey={location.pathname} className="me-auto text-nowrap" variant="underline">
              <Nav.Link as={Link} to="/" eventKey="/">
                Bemutatkozás
              </Nav.Link>
              <Nav.Link as={Link} to="/services" eventKey="/services">
                Szolgáltatások
              </Nav.Link>
              <Nav.Link as={Link} to="/information" eventKey="/information">
                Információk
              </Nav.Link>
              <Nav.Link as={Link} to="/prices" eventKey="/prices">
                Árak
              </Nav.Link>
              <Nav.Link as={Link} to="/documents" eventKey="/documents">
                Dokumentumok
              </Nav.Link>
              <Nav.Link as={Link} to="/contacts" eventKey="/contacts">
                Kapcsolat
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
