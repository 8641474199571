import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";

function Content(props) {
  return (
    <main>
      <Container>
        <Alert variant="success" className="text-center my-3">
          Időpont egyeztetésre az <Link to="/contacts">elérhetőségek között megadott telefonszámon</Link> van lehetőség. Várom Önt szeretettel!
        </Alert>
        <div className="my-3">{props.children}</div>
      </Container>
    </main>
  );
}

export default Content;
