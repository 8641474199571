import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

export default function ContactsPage() {
  return (
    <div id="contacts-page">
      <p className="h1 my-5 text-center">Kapcsolat</p>
      <p className="h3">Elérhetőségek</p>
      <p>Időpont egyeztetés, információ:</p>
      <p>
        <i className="bi bi-telephone-fill"></i> <a href="tel:+36303676916">+36 30 367 6916</a>
      </p>
      <p>További elérhetőségek:</p>
      <p>
        <i className="bi bi-envelope-at-fill"></i> <a href="mailto:doradietetika@gmail.com">doradietetika@gmail.com</a>
      </p>
      <p>
        <i className="bi bi-facebook"></i>{" "}
        <a href="https://www.facebook.com/profile.php?id=61558792421651" target="_blank" rel="noreferrer">
          Dóra Dietetika
        </a>
      </p>
      <p className="h3">Rendelő</p>
      <p>
        <i className="bi bi-geo-alt-fill"></i> 2316 Tököl, Mester utca 1. (bejárat az épület bal oldalán, a parkoló felől)
      </p>
      <p>A rendelő közvetlen közelében ingyenes parkoló, a szomszédos gyógyszertár mellett pedig kerékpár tároló is található.</p>
      <p className="h3">Nyitvatartás</p>
      <p>Munkanapokon 8:00 - 16:00, illetve egyéni megbeszélés alapján.</p>
      <Alert variant="warning" className="w-100">
        Minden esetben előzetes időpont egyeztetés szükséges, ezzel kapcsolatban kérem keressen a fent megadott telefonszámon!
      </Alert>
      <p className="h3">Fizetési Lehetőségek</p>
      <p>
        <i className="bi bi-cash"></i> A rendelőben készpénzzel
      </p>
      <p>
        <i className="bi bi-credit-card-fill"></i> A rendelőben bankkártyával
      </p>
      <p>
        <i className="bi bi bi-hospital"></i> A rendelőben a következő egészségpénztári kártyákkal:
        <p style={{ marginLeft: "20px" }}>
          <ul>
            <li>Életút Pénztárak</li>
            <li>Generali Egészség- és Önsegélyező Pénztár</li>
            <li>MBH Gondoskodás Egészségpénztár</li>
            <li>OTP Egészségpénztár</li>
            <li>Prémium Egészségpénztár</li>
            <li>Vasutas Egészség- és Önsegélyező Pénztár</li>
          </ul>
        </p>
      </p>
      <p>
        <i className="bi bi-bank2"></i> Banki átutalással a 10401983-50527052-67671001 számlaszámra, vagy a doradietetika@gmail.com másodlagos azonosítóra
      </p>
      <p className="h3">Vállalkozás adatai</p>
      <Table bordered className="w-auto">
        <tbody>
          <tr>
            <th>Név</th>
            <td>Kisszékelyi Dóra Anita E.V.</td>
          </tr>
          <tr>
            <th>Adószám</th>
            <td>90006421-1-33</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
