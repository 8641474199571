import { Link } from "react-router-dom";

export default function InformationPage() {
  return (
    <div id="information-page">
      <p className="h1 my-5 text-center">Információk</p>
      <p className="h3">Hogyan zajlik egy tanácsadás?</p>
      <p className="h5">A tanácsadás előtt</p>
      <ul>
        <li>
          A tanácsadásra minden esetben előzetes <Link to="/contacts">időpont egyeztetés</Link> szükséges.
        </li>
        <li>Miután időpontot foglalt hozzám, küldök Önnek egy email-t, amely néhány kitöltendő dokumentumot fog tartalmazni.</li>
        <li>Kérem, hogy a kitöltött dokumentumokat az időpontja előtt legkésőbb 2 munkanappal válasz email-ben küldje el.</li>
      </ul>
      <p className="h5">A tanácsadás közben</p>
      <ul>
        <li>A tanácsadást egy BIA készülékkel történő súly és testösszetétel méréssel, magasságméréssel és derékkörfogat méréssel kezdjük.</li>
        <li>Ezután átbeszéljük az Ön által elküldött anyagokat.</li>
        <li>Áttekintjük a javaslataimat és válaszolok a felmerülő kérdésekre.</li>
      </ul>
      <p className="h5">A tanácsadás után</p>
      <ul>
        <li>A tanácsadói anyagot elküldöm Önnek email-ben.</li>
        <li>Mivel a tanácsadás során rengeteg új információt kap, a felmerülő kérdések megválaszolására egy 7 napos email konzultációs lehetőséget biztosítok.</li>
        <li>Az email konzultáció után lehetősége van kontroll időpontot foglalni, vagy további mintaétrendeket vásárolni.</li>
      </ul>
      <p className="h3">Tudnivalók a BIA testösszetétel mérő műszerekről</p>
      <p>
        A BIA műszer a bioimpedancia elvén működik: enyhe áramot vezet keresztül a testen, és méri a különböző lágy szövetek ellenállását. Az így kapott kapott adatokból százalékos
        eloszlásban mutatja meg, hogy mennyi zsír, illetve izom van a szervezetünkben.
      </p>
      <ul>
        <li>Terhesség és pacemaker esetén nem végezhető el a vizsgálat!</li>
        <li>A vizsgálat mezítláb történik és teljesen fájdalommentes.</li>
        <li>A mérést menstruáció alatt nem javasolt elvégezni, mert torzítja a kapott eredményeket, így ebben az esetben új időpontot kell kérni.</li>
        <li>A vizsgálat előtt két órával már nem javasolt étkezni, és előtte nagyobb mennyiségű folyadékot fogyasztani, mert ezek torzíthatják a kapott értékeket.</li>
        <li>Edzés után közvetlenül nem javasolt a mérés elvégzése.</li>
      </ul>
    </div>
  );
}
