import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const services = [
  {
    img: "/images/services/pregnancy-1749858_640.webp",
    title: "Gyermekvállalás, hozzátáplálás, egészségtudatos nevelés",
    body: (
      <>
        <p>
          Ha nincs kialakult betegség, de szeretne szakembertől segítséget kérni egészségtudatos étkezési szokások kialakításához gyermekvállalás előtt, várandósság alatt,
          hozzátápláláskor, kisgyermek, vagy akár felnőtt korban, akkor várom szeretettel a rendelőmben.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/lecturer-8339698_640_modified.webp",
    title: "Prevenciós előadás",
    body: (
      <>
        <p>Kis-, vagy nagycsoportos előadások cégeknek, iskoláknak, stb.</p>
      </>
    ),
  },
  {
    img: "/images/services/cream-pie-pregnancy-8613956_640_modified.webp",
    title: "Gesztációs diabétesz (GDM)",
    body: (
      <>
        <p>
          A terhességi cukorbetegség a terhességhez kapcsolódó leggyakoribb kóros állapot. Általában nem, vagy csak alig okoz panaszt, ezért a legtöbb esetben a 24-28. hét között
          esedékes terheléses laborvizsgálat során derül ki.
        </p>
        <p>
          Ha Önnél az orvosa gesztációs diabéteszt diagnosztizált, és szüksége van segítségre az étrend összeállításához, akkor kérem keressen bizalommal, mivel Ön és a születendő
          baba érdekében is fontos a diéta mielőbbi megkezdése.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/salad-2756467_640_modified.webp",
    title: "Inzulinrezisztencia",
    body: (
      <>
        <p>
          Az inzulinrezisztencia modern társadalmunk gyakori megbetegedése, sokszor tünetmentes és csak egy szűrés során derül csak rá fény. Alapvetően a szénhidrát anyagcsere
          zavara, de rendkívül változatos tünetekért lehet felelős.
        </p>
        <p>
          Ha Önt orvosa inzulin rezisztenciával diagnosztizálta, akkor érdemes mihamarabb megkezdeni a diétát, mivel a kezeletlen inzulinrezisztencia kettes típusú cukorbetegség és
          egyéb szövődmények kialakulásához vezethet.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/woman-3186730_640_modified.webp",
    title: "Policisztás ovárium szindróma (PCOS)",
    body: (
      <>
        <p>
          A PCOS a női szervezet hormonális megbetegedése, amely minden tizedik nőt érint. Élethosszig tartó kezelést igényel, melyben fontos szerepet kap a megfelelő diéta,
          különösen akkor, ha inzulinrezisztencia és cukorbetegség is társul hozzá.
        </p>
        <p>Ha Önt PCOS-el diagnosztizálták, akkor várom szeretettel a rendelőmben a megfelelő dietetoterápiás kezelés megkezdéséhez.</p>
      </>
    ),
  },
  {
    img: "/images/services/diabetes-2058045_640_modified.webp",
    title: "Kettes típusú cukorbetegség",
    body: (
      <>
        <p>A cukorbetegség ma már népbetegségnek számít, sokszor csak akkor diagnosztizálják, amikor már kialakultak a szövődmények.</p>
        <p>
          Ha Önt orvosa dietetikushoz küldte, akkor várom szeretettel a rendelőmben, hogy összeállítsunk egy személyre szabott étrendet a megfelelő vércukorszint biztosítása
          érdekében.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/endocrinologist-8852100_640_modified.webp",
    title: "Pajzsmirigy betegségek",
    body: (
      <>
        <p>
          A pajzsmirigy egy belső elválasztású mirigy, hormonjai fontos szerepet játszanak testünk anyagcsere folyamatiban, így működési zavarai (pl. hyperthyreosis, hypothyreosis,
          hashimoto thyreoiditis) sokféle tünetet okozhatnak.
        </p>
        <p>Az orvosi terápia mellett a megfelelően összeállított diéta segítheti a tünetek enyhülését, egy jobb általános állapot elérését.</p>
      </>
    ),
  },
  {
    img: "/images/services/cook-2065065_640_modified.webp",
    title: "Köszvény, emelkedett húgysav szint",
    body: (
      <>
        <p>
          A köszvény az anyagcsere zavarából adódó betegség. Megnövekszik a vér húgysavtartalma és húgysavas sók rakódnak le a különböző szövetekben, elsősorban az ízületekben.
          Típusos esetben a fájdalom rohamokban is jelentkezhet.
        </p>
        <p>
          Ha nem is alakul ki roham, az emelkedett húgysavszint önmagában is több betegség (szív és érrendszeri megbetegedések, vesebetegség, magas vérnyomás) rizikó faktora, így
          ebben az esetben is fontos a kezelés.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/button-1398594_640_modified.webp",
    title: "Tejcukorérzékenység (laktózintolerancia)",
    body: (
      <>
        <p>
          A laktózérzékenység egy laktóz tartalmú élelmiszerek fogyasztása után jelentkező tünetegyüttes. Oka a tejcukorbontó enzim (laktáz) hiányos vagy elégtelen termelése.
          Fontos megkülönböztetni a tejfehérje allergiától!
        </p>
        <p>
          A diéta összeállításánál fontos, hogy csak azok az élelmiszercsoportok kerüljenek kizárásra, amik valóban problémát okoznak. Ha Önnél vagy gyermekénél átmeneti vagy
          végleges tejcukor mentes diétát írt elő az orvos, akkor kérem keressen bizalommal.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/egg-995047_640.webp",
    title: "Táplálékallergiák",
    body: (
      <>
        <p>
          Ha Önnél vagy gyermekénél orvosa táplálék allergiát diagnosztizált (tehéntej, szója, földimogyoró, tojás, tenger gyümölcsei, stb.), akkor a terápia a tünetet okozó
          élelmianyagot, élelmiszert, adalékanyagot elkerülő étrend. Fontos, hogy a diéta úgy legyen összeállítva, hogy a beteg tápanyagigénye biztosítva legyen.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/smoothies-3176371_640_modified.webp",
    title: "Fruktóz malabszorpció",
    body: (
      <>
        <p>
          A fruktóz malabszorpció egy olyan állapot, amelyben a szervezet nem képes megfelelően felszívni a fruktózt a vékonybélből. Ez az emésztési probléma különbözik a genetikai
          eredetű, ritka fruktóz intoleranciától (örökletes fruktóz intolerancia, HFI).
        </p>
        <p>A dietoterápia célja a fruktóz-, és szacharóz bevitel ideiglenes csökkentése, majd egyéni tolerancia szerint az individuális étrend összeállítása.</p>
      </>
    ),
  },
  {
    img: "/images/services/gluten-free-6896578_640_modified.webp",
    title: "Lisztérzékenység (cöliákia)",
    body: (
      <>
        <p>
          A cöliákia, vagy régi nevén lisztérzékenység egy autoimmun eredetű megbetegedés, amely az egész szervezet működésére kihat: emésztőrendszer, csontrendszer, bőr. A tünetek
          bármely életkorban jelentkezhetnek és rendkívül változatosak lehetnek.
        </p>
        <p>A betegség nem gyógyítható, nem nőhető ki, de gluténmentes diétával nagyon jól karbantartható.</p>
      </>
    ),
  },
  {
    img: "/images/services/stomachache-6509430_640_modified.webp",
    title: "Refluxbetegség (GERD)",
    body: (
      <>
        <p>
          A refluxbetegség, más néven gastrooesophagealis reflux betegség (GERD) olyan állapot, amelyben a gyomorsav és egyéb gyomortartalom visszafolyik a nyelőcsőbe. Ez
          irritálhatja a nyelőcső nyálkahártyáját és különböző tüneteket okozhat.
        </p>
        <p>
          A refluxbetegség kezelése személyre szabott megközelítést igényel, amely magában foglalja az életmódbeli változtatásokat, gyógyszeres kezelést és súlyos esetekben
          sebészeti beavatkozást is. A dietetikussal való konzultáció segíthet a tünetek enyhítésében és az életminőség javításában.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/liver-6694036_640_modified.webp",
    title: "Epeutak megbetegedései",
    body: (
      <>
        <p>
          Az epeutak megbetegedései különböző rendellenességeket és betegségeket foglalnak magukban, amelyek az epehólyagot, az epevezetékeket és az epe termelését és szállítását
          érintik, például epekőbetegség, epehólyag gyulladás, epeúti elzáródás, epevezeték gyulladás, primer szklerotizáló cholangitis (PSC).
        </p>
        <p>
          Az epeutak megbetegedéseinek kezelése személyre szabott megközelítést igényel. A dietetikussal való konzultáció segíthet a tünetek enyhítésében és az életminőség
          javításában.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/stomach-7111053_640_modified.webp",
    title: "Gyomorfekély, nyombélfekély",
    body: (
      <>
        <p>
          A gyomorfekély és a nyombélfekély gyakori emésztőrendszeri megbetegedések. Kialakulásukat a nyálkahártya védelmét biztosító tényezők és a nyálkahártyát károsító hatások
          egyensúlyának megbomlása okozza.
        </p>
        <p>
          A diéta célja, hogy az étrend fedezze a beteg tápanyagszükségletét, ellensúlyozza a vitaminhiányos állapotot, óvja a gyulladt nyálkahártyát, alkalmazkodjon a megváltozott
          savviszonyokhoz. A dietetikussal való konzultáció segíthet a tünetek enyhítésében és az életminőség javításában.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/belly-3865433_640_modified.webp",
    title: "Székrekedés",
    body: (
      <>
        <p>
          A székrekedés (konstipáció) olyan állapot, amelyben a székletürítés nehézkes, ritka, és a széklet kemény vagy száraz. A székrekedésnek számos oka lehet, beleértve az
          étrendet, az életmódot, a gyógyszereket és bizonyos egészségügyi állapotokat.
        </p>
        <p>Az egyéni igények és körülmények figyelembevételével a székrekedés hatékonyan kezelhető és megelőzhető.</p>
      </>
    ),
  },
  {
    img: "/images/services/abdomen-8762848_640.jpg",
    title: "Diverticulosis, diverticulitis",
    body: (
      <>
        <p>A diverticulosis egy olyan állapot, melynek során kis tasakok vagy zsákok, úgynevezett diverticulumok alakulnak ki a vastagbél falán.</p>
        <p>
          A diverticulosis kezelése és megelőzése az életmódbeli változtatásokra összpontosít. Kiemelten fontos a rostban gazdag étkezés és a rendszeres testmozgás. A dietetikai
          konzultáció segíthet a megfelelő étrend kialakításában.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/ai-generated-8410772_640.jpg",
    title: "Krónikus obstruktív tüdőbetegség (COPD)",
    body: (
      <>
        <p>
          A COPD egy progresszív tüdőbetegség, amely a légutak tartós szűkületét és légzési nehezítettséget okoz. Két fő betegségcsoportot foglal magában: a krónikus hörghurutot és
          az emphysemát (tüdőtágulást).
        </p>
        <p>
          Kezelése életmódbeli változtatásokat és gyógyszeres kezelést is igényel. A megfelelően kialakított diéta nagyban hozzájárulhat a beteg közérzetének javításához és az
          állapotromlás megelőzéséhez.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/woman-6930601_640_modified.webp",
    title: "Endometriózis",
    body: (
      <>
        <p>
          Bár az endometriózisra nincs speciális étrend, de a kutatások és a tapasztalatok azt mutatják, hogy bizonyos élelmiszerek elkerülése, illetve megadott élelmiszerek
          fogyasztása nagyban hozzájárulhat a hormonális egyensúly kialakulásához. Ennek hatására a gyulladásos folyamatok csökkennek, ami sok esetben a tünetek enyhüléséhez
          vezethet.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/blood-clot-7118517_640_modified.webp",
    title: "Emelkedett vérzsír értékek",
    body: (
      <>
        <p>
          A vér magas zsírsavszintje (koleszterin, triglicerid) növeli az érelmeszesedés, a szívinfarktus és bizonyos daganatos betegségek kialakulásának kockázatát, de megfelelő
          étrenddel, életmóddal, csökkenthető ez a rizikó.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/body-8771260_640_modified.webp",
    title: "Májbetegségek",
    body: (
      <>
        <p>
          A máj a legnagyobb belső szervünk, a hasüreg jobb felső részében, a rekeszizom alatt található. Nélkülözhetetlen szerepet tölt be a szervezet méregtelenítésében, a
          szénhidrát-, zsír- és fehérje-anyagcseréjében, az epesav termelésében, valamint a vérképzésben. Májbetegségek közé tartoznak például a hepatitisek, a zsírmáj és a
          májcirózis.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/kidney-6009357_640_modified.webp",
    title: "Vesebetegségek",
    body: (
      <>
        <p>
          A vesebetegségek a veséket érintő rendellenességek, amelyek befolyásolhatják a szervezet méregtelenítését, folyadék- és elektrolit-egyensúlyát, hormon termelését,
          valamint a vérnyomás szabályozását. Ide tartozik például az akut glomerulonephritis, a nephrosis szindróma, a vesekő, és a krónikus veseelégtelenség.
        </p>
        <p>
          A diéta célja a vesék terhelésének csökkentése, a toxinok felhalmozódásának megelőzése és az optimális tápláltsági állapot fenntartása. A vesebetegségek különböző
          stádiumai és a társbetegségek egyéni diétás megközelítést igényelnek. Dietetikussal való konzultáció segíthet a megfelelő étrend kialakításában.
        </p>
      </>
    ),
  },
  {
    img: "/images/services/blood-pressure-1006791_640_modified.webp",
    title: "Magas vérnyomás (hipertónia)",
    body: (
      <>
        <p>
          A magas vérnyomás, vagy más néven hipertónia az az állapot, amikor a vérnyomás a normál értéknél magasabb. A magas vérnyomás súlyos egészségügyi problémákhoz vezethet
          (szív és érrendszeri betegségek, vesebetegség, agyvérzés, stb.), így fontos a céltartományon belüli érték biztosítása. Rendszeres orvosi ellenőrzéssel, életmódbeli,
          étrendi változtatásokkal nagyon jól kezelhető.
        </p>
        <p>Kérem hívjon bizalommal, ha segítséget szeretne a megfelelő diéta összeállításában.</p>
      </>
    ),
  },
];

function ServiceCard({ img, title, text, body, ...props }) {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Card className="h-100">
        <Card.Img variant="top" src={img} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
        </Card.Body>
        <Card.Footer>
          <Button variant="secondary" onClick={() => setModalShow(true)}>
            Bővebben
          </Button>
        </Card.Footer>
      </Card>
      <ServiceCardModal title={title} body={body} show={modalShow} onHide={() => setModalShow(false)}>
        {props.children}
      </ServiceCardModal>
    </>
  );
}

function ServiceCardModal(props) {
  const navigate = useNavigate();
  return (
    <Modal {...props} fullscreen="md-down" centered>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => navigate("/contacts")}>
          Időpont Egyeztetés
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          Bezárás
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function ServicesPage() {
  return (
    <div id="services-page">
      <p className="h1 my-5 text-center">Szolgáltatások</p>
      <p className="h3">Miben tud segíteni egy dietetikus?</p>
      <p>
        A dietetikus olyan felsőfokú végzettségű, diplomás szakember, aki egészséges és betegségben szenvedő emberek számára egyaránt segíteni tud a számukra legmegfelelőbb étrend
        kialakításában.
      </p>
      <p className="h3">Miben NEM tud segíteni egy dietetikus?</p>
      <p>A dietetikus nem orvos, nem diagnosztizál betegségeket és nem ad receptet/beutalót.</p>
      <p className="h3">Milyen esetben fordulhat hozzám?</p>
      <p>
        Az alábbiakban összegyűjtöttem néhány betegséget és témakört melyben dietetikusként segíteni tudok Önnek. Ha esetleg más témában lenne szüksége tanácsadásra, kérem akkor is
        keressen bizalommal, és megbeszéljük, hogy tudok-e segíteni.
      </p>
      <Row className="gy-3" xs={1} md={2} lg={4}>
        {services
          .sort((s1, s2) => s1.title.localeCompare(s2.title))
          .map((s) => (
            <Col key={s.title}>
              <ServiceCard img={s.img} title={s.title}>
                {s.body}
              </ServiceCard>
            </Col>
          ))}
      </Row>
    </div>
  );
}
