import React from "react";
import "./Footer.css";

function Footer() {
  const buildDate = process.env.REACT_APP_BUILD_DATE ?? "2024.08.01.";
  return (
    <footer className="Footer">
      <div className="my-3 text-center">
        ©️ Dóra Dietetika {new Date().getFullYear()} - Minden jog fenntartva | Utolsó frissítés: {buildDate} | Dietetikusunk tanácsára az oldal nem használ sütiket 😉
      </div>
    </footer>
  );
}

export default Footer;
