import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./PricesPage.css";

const prices = [
  {
    title: "Egyéni tanácsadás",
    price: "Első alkalom: 17,000 Ft | Kontroll: 10,000 Ft",
    body: (
      <>
        <p>Időtartam: első alkalom 60-90 perc, kontroll 45-60 perc</p>
        <p>Személyesen vagy online (online tanácsadás esetén a számla előre fizetendő)</p>
        <p>Tartalma:</p>
        <ul>
          <li>Az előre elküldött anamnézis lap elemzése, átbeszélése (ha nincs előre elküldve, akkor az anamnézis felvétele is a tanácsadási időből megy el)</li>
          <li>Az előre elküldött táplálkozási napló elemzése, átbeszélése (ha nincs előre elküldve, akkor az elemzés is a tanácsadási időből megy el)</li>
          <li>Antropometriai vizsgálat (magasság, testsúly, testösszetétel és derékkörfogat mérés)</li>
          <li>Táplálkozási tanácsadás</li>
          <li>Tanácsadói anyag, amely minden fontos információt tartalmaz (elektronikus formában)</li>
          <li>7 napos email konzultációs lehetőség a tanácsadás után</li>
        </ul>
        <p>Kontroll időpont az első tanácsadást követő 12 hónapon belül érhető el, azonos betegséggel/témaval kapcsolatban.</p>
      </>
    ),
  },
  {
    title: "Egyéni csomag (tanácsadás + mintaétrend)",
    price: "Első alkalom: 25,000 Ft | Kontroll: 18,000 Ft",
    body: (
      <>
        <p>Időtartam: első alkalom 60-90 perc, kontroll 45-60 perc</p>
        <p>Személyesen vagy online (online tanácsadás esetén a számla előre fizetendő)</p>
        <p>Tartalma:</p>
        <ul>
          <li>Az előre elküldött anamnézis lap elemzése, átbeszélése (ha nincs előre elküldve, akkor az anamnézis felvétele is a tanácsadási időből megy el)</li>
          <li>Az előre elküldött táplálkozási napló elemzése, átbeszélése (ha nincs előre elküldve, akkor az elemzés is a tanácsadási időből megy el)</li>
          <li>Antropometriai vizsgálat (magasság, testsúly, testösszetétel és derékkörfogat mérés)</li>
          <li>Táplálkozási tanácsadás</li>
          <li>Tanácsadói anyag, amely minden fontos információt tartalmaz (elektronikus formában)</li>
          <li>7 napos email konzultációs lehetőség a tanácsadás után</li>
          <li>7 napos, személyre szabott mintaétrend (receptekkel és elkészítési javaslatokkal, elektronikus formában)</li>
        </ul>
        <p>Kontroll időpont az első tanácsadást követő 12 hónapon belül érhető el, azonos betegséggel/témaval kapcsolatban.</p>
      </>
    ),
  },
  {
    title: "Családi csomag (tanácsadás + mintaétrend)",
    price: "40,000 Ft",
    body: (
      <>
        <p>Időtartam: 60-90 perc</p>
        <p>Kizárólag személyesen</p>
        <p>Mikor ajánlott?</p>
        <p>
          Ha az egész családot érinti egy téma, vagy több gyermek van és szeretné egészségtudatos táplálkozásra nevelni őket, de korból, nemből, mozgásformából adódóan mások az
          igényeik, akkor mindenkinek személyre szabom az étrendjét. Közös háztartásban élő közeli hozzátartozók számára érhető el, azonos betegséggel/témaval kapcsolatban.
        </p>
        <p>Tartalma:</p>
        <ul>
          <li>Az előre elküldött anamnézis lap elemzése, átbeszélése (ha nincs előre elküldve, akkor az anamnézis felvétele is a tanácsadási időből megy el)</li>
          <li>Az előre elküldött táplálkozási napló elemzése, átbeszélése (ha nincs előre elküldve, akkor az elemzés is a tanácsadási időből megy el)</li>
          <li>Antropometriai vizsgálat (magasság, testsúly, testösszetétel és derékkörfogat mérés)</li>
          <li>Táplálkozási tanácsadás</li>
          <li>Tanácsadói anyag, amely minden fontos információt tartalmaz (elektronikus formában)</li>
          <li>7 napos email konzultációs lehetőség a tanácsadás után</li>
          <li>7 napos, személyre szabott mintaétrend (receptekkel és elkészítési javaslatokkal, elektronikus formában)</li>
        </ul>
      </>
    ),
  },
  {
    title: "Mintaétrend készítés",
    price: "10,000 Ft",
    body: (
      <>
        <p>Mikor ajánlott?</p>
        <p>Tanácsadást követően, ha nincs kérdése, de szeretne még több receptet.</p>
        <p>Tartalma:</p>
        <ul>
          <li>7 napos, személyre szabott mintaétrend (receptekkel és elkészítési javaslatokkal, elektronikus formában)</li>
        </ul>
      </>
    ),
  },
  {
    title: "Prevenciós előadás",
    price: "Egyéni ár",
    body: (
      <>
        <p>Iskolákban, óvodákban, munkahelyeken egyéni megbeszélés szerint.</p>
      </>
    ),
  },
];

function PriceAccordionItem({ price }) {
  return (
    <>
      <Accordion.Item eventKey={price.title}>
        <Accordion.Header>
          <Container>
            <Row className="fw-bold">
              <Col>{price.title}</Col>
              <Col md="auto">{price.price}</Col>
            </Row>
          </Container>
        </Accordion.Header>
        <Accordion.Body>{price.body}</Accordion.Body>
      </Accordion.Item>
    </>
  );
}

function PriceAccordion({ prices }) {
  return (
    <>
      <Accordion alwaysOpen>
        {prices.map((p, i) => (
          <PriceAccordionItem key={i} price={p}></PriceAccordionItem>
        ))}
      </Accordion>
    </>
  );
}

export default function PricesPage() {
  return (
    <div id="prices-page">
      <p className="h1 my-5 text-center">Árak</p>
      <PriceAccordion prices={prices}></PriceAccordion>
    </div>
  );
}
