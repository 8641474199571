import { Link } from "react-router-dom";

export default function PricesPage() {
  return (
    <div id="documents-page">
      <p className="h1 my-5 text-center">Dokumentumok</p>
      <p className="h3">Jogi Dokumentumok</p>
      <p>
        <i className="bi bi-file-pdf"></i>&nbsp;
        <Link to="/docs/Dora_Dietetika_ASZF.pdf" target="_blank" rel="noopener">
          Általános Szerződési Feltételek
        </Link>
      </p>
      <p>
        <i className="bi bi-file-pdf"></i>&nbsp;
        <Link to="/docs/Dora_Dietetika_Egeszsegugyi_Szolgaltatasi_Szerzodes.pdf" target="_blank" rel="noopener">
          Egészségügyi Szolgáltatási Szerződés
        </Link>
      </p>
      <p>
        <i className="bi bi-file-pdf"></i>&nbsp;
        <Link to="/docs/Dora_Dietetika_Adatvedelmi_Tajekoztato.pdf" target="_blank" rel="noopener">
          Adatvédelmi Tájékoztató
        </Link>
      </p>
      <p>
        <i className="bi bi-file-pdf"></i>&nbsp;
        <Link to="/docs/Dora_Dietetika_Paciens_Adatkezelesi_Nyilatkozat.pdf" target="_blank" rel="noopener">
          Páciens Adatkezelési Nyilatkozata
        </Link>
      </p>
      <p>
        <i className="bi bi-file-pdf"></i>&nbsp;
        <Link to="/docs/Dora_Dietetika_Panaszkezelesi_Szabalyzat.pdf" target="_blank" rel="noopener">
          Panaszkezelési Szabályzat
        </Link>
      </p>
    </div>
  );
}
